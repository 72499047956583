<template>
  <v-col cols="12">
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <span class="text-h1 text-uppercase">Oops!</span>
          <h2>404 - The Page can't be found</h2>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
    name: "NotFoundComponent",
    created() {
        window.vueEvent.$emit('toggleProfile', false);
    },
    destroyed() {
        window.vueEvent.$emit('toggleProfile', true);
    }
}
</script>

<style scoped>
    #notfound {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
    }
    .notfound-404 {
        position: relative;
    }
    .notfound-404 span {
        font-size: 15rem!important;
    }
    .notfound-404 h2 {
        position: absolute;
        bottom: 0;
        right: 15%;
        background-color: #fff;
        padding: 5px 30px;
        text-transform: uppercase;
        font-size: 25px;
    }
</style>